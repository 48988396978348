import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import React from 'react';
import classNames from 'classnames';
import { Step } from 'components/pages-content/home/components/Step';
import { whyTheRetmItems } from '../../mocks/mocks';

export const WhyTheRetmSection = () => {
  const { t } = useTranslation('home-page');

  return (
    <div className="container">
      <p
        className={classNames(
          'text-center ltr:font-500 rtl:font-400 text-body-2 md:text-body-1 ltr:leading-[28px] rtl:leading-[34px]',
          'max-w-[666px] m-auto mb-[40px] md:mb-[60px] 2xl:mb-[80px]'
        )}
      >
        {t(
          'Whether you are personal owner or business owner having one or thousands of units, you can add unlimited number of properties or tenants to RETM. our integrated and interconnected system suit your needs.'
        )}
      </p>

      <div
        className={classNames(
          'flex flex-col flex-center xl:flex-row ',
          'space-y-[30px] md:space-y-[40px] space-x-0 xl:space-y-0 xl:space-x-[65px] rtl:space-x-reverse'
        )}
      >
        {whyTheRetmItems.map((item) => (
          <div key={item.step} className="flex-center">
            <Image
              src={item.image}
              alt="illustration"
              width={310}
              height={250}
              className="flex-1"
            />
            <div
              className={classNames(
                'home-card-shadow-rounded border-0 w-[330px] py-[22px] px-[26px] rtl:pr-[40px] flex-1 z-10 text-center bg-white-opacity',
                'ltr:ml-[-295px] rtl:mr-[-295px] md:ltr:ml-[-250px] md:rtl:mr-[-250px]'
              )}
            >
              <Step
                step={item.step}
                text={t(item.text)}
                textClassName="leading-[24px]"
                title={t(item.title)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
