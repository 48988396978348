import classNames from 'classnames';

type Props = {
  wrapClassName?: string;
  textClassName?: string;
  step: string;
  text: string;
  title?: string;
};

export const Step = ({
  wrapClassName,
  textClassName,
  step,
  text,
  title,
}: Props) => {
  return (
    <div className={classNames('relative', wrapClassName)}>
      <p
        className={classNames(
          'font-Harietta text-brand text-[50px] leading-[60px] font-700 z-10',
          'absolute -top-5 ltr:-left-6 rtl:-right-8'
        )}
      >
        {step}
      </p>
      {title && (
        <h5 className="z-20 relative ltr:font-700 rtl:font-600 text-secondary mb-[10px]">
          {title}
        </h5>
      )}
      <p
        className={classNames(
          'ltr:font-500 rtl:font-400 text-body-3 rtl:leading-[24px] z-20 relative xl:rtl:pl-[50px]',
          textClassName
        )}
      >
        {text}
      </p>
    </div>
  );
};
