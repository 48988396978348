import React, { useState } from 'react';
import { Tabs } from './Tabs';
import { HowItWorksSlider } from './HowItWorksSlider';
import { howItWorksItems } from '../../mocks/mocks';

export const HowItWorksSection = () => {
  // todo remove after discussion
  // const [activeTab, setActiveTab] = useState<number>(0);

  return (
    <div className="container">
      {/* todo remove after discussion */}
      {/* <div className='flex ltr:justify-end rtl:flex-start lg:mb-[-90px] md:mb-[60px] mb-[40px]'>
      <Tabs activeTab={activeTab} onClickTab={(tab: number) => setActiveTab(tab)}/>
    </div> */}
      <HowItWorksSlider items={howItWorksItems} />
    </div>
  );
};
