import React, { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Button from 'components/elements/Button';
import { AboutSection } from './sections/about/AboutSection';
import { HowItWorksSection } from './sections/how-it-works/HowItWorksSection';
import { WhyTheRetmSection } from './sections/why-the-retm/WhyTheRetmSection';
import { TestimonialsSection } from './sections/testimonials/TestimonialsSection';
import { ContactFormSection } from './sections/contact-form/ContactFormSection';
import { FAQSection } from './sections/faq/FAQSection';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { faqItems } from './mocks/mocks';
import Illustration from '/public/images/home/illust4.png';

const HomePage = () => {
  const { t } = useTranslation('home-page');
  const { t: footerTraslation } = useTranslation('dashboard-footer');
  const router = useRouter();

  const goToRegister = () => {
    if (router.pathname === '/register') return;
    router.replace('/register');
  };

  return (
    <div>
      <div className="home-wrap">
        <AboutSection />
      </div>

      <div className="mt-[80px] md:mt-[100px] mb-[20px] md:mb-[30px] lg:mt-[120px]">
        <div className="flex-center rtl:flex-row-reverse">
          <div className="xs:bg-primary w-full xs:h-[1px] flex-1" />
          <h3 className="home-title text-center mx-[50px]">
            {t('RETM Personalized Service, Proven Results')}
          </h3>
          <div className="flex-1" />
        </div>
      </div>
      <div className="mb-[30px] md:mb-[40px] lg:mb-[60px]">
        <div className="flex-center rtl:flex-row-reverse">
          <h3 className=" text-center text-primary mx-[50px] text-subtitle-1 lg:text-headline-2">
            {t(
              'Here is how we make your experience smooth in every step of your journey.'
            )}
          </h3>
        </div>
      </div>

      <div className="home-wrap">
        <HowItWorksSection />
      </div>

      <div className="mt-[60px] md:mt-[100px] mb-[60px] md:mb-[80px] lg:mt-[140px] lg:mb-[60px]">
        <div className="flex-center rtl:flex-row-reverse">
          <div className="flex-1" />
          <h3 className="home-title text-center mx-[50px]">{t('Why RETM')}</h3>
          <div className="xs:bg-primary w-full xs:h-[1px] flex-1" />
        </div>
      </div>

      <div className="home-wrap">
        <WhyTheRetmSection />
      </div>

      <div className="text-center mt-[60px]">
        <Button
          label={t('Get started')}
          onClick={goToRegister}
          className="min-w-[160px]"
        />
      </div>

      <div className="mt-[80px] md:mt-[100px] mb-[20px] md:mb-[30px] lg:mt-[120px]">
        <div className="flex-center rtl:flex-row-reverse">
          <div className="xs:bg-primary w-full xs:h-[1px] flex-1" />
          <h3 className="home-title text-center mx-[50px]">
            {t('Testimonials')}
          </h3>
          <div className="flex-1" />
        </div>
      </div>
      <div className="mb-[30px] md:mb-[40px] lg:mb-[60px]">
        <div className="flex-center rtl:flex-row-reverse">
          <h3 className=" text-center text-primary mx-[50px] text-subtitle-1 lg:text-headline-2">
            {t(
              'Please take a moment to read reviews of our many satisfied clients.'
            )}
          </h3>
        </div>
      </div>

      <div className="home-wrap">
        <TestimonialsSection />
      </div>

      <div
        className="mt-[80px] mb-[60px] md:mt-[100px] md:mb-[80px] lg:mt-[140px]"
        id="faq"
      >
        <div className="flex-center rtl:flex-row-reverse">
          <div className="flex-1" />
          <h3 className="home-title text-center mx-[50px]">
            {footerTraslation('faq')}
          </h3>
          <div className="xs:bg-primary w-full xs:h-[1px] flex-1" />
        </div>
      </div>

      <div className="home-wrap mb-[110px] 2xl:mb-0">
        <FAQSection faqItems={faqItems} imageUrl={Illustration.src} />
      </div>

      <div className="hidden 2xl:block text-center mt-[80px] mb-[140px]">
        <Button
          label={t('Get started')}
          onClick={goToRegister}
          className="min-w-[160px]"
        />
      </div>

      <div className="home-wrap">
        <ContactFormSection />
      </div>
    </div>
  );
};

export default HomePage;
