import Image from 'next/image';
import classNames from 'classnames';
import Illustration1 from '/public/images/home/illust1-desc.png';
import Illustration1Tablet from '/public/images/home/illust1-tabl.png';
import Illustration1Mobile from '/public/images/home/illust1-mob.png';
import { useTranslation } from 'next-i18next';
import { BenefitSection } from 'components/pages-content/home/sections/about/BenefitSection';

export const AboutSection = () => {
  const { t } = useTranslation('home-page');

  return (
    <>
      <div className="flex justify-start container rtl:px-[27px]">
        <div
          className={classNames(
            'ltr:min-h-[830px]  rtl:min-h-[670px]',
            'ltr:xxs:min-h-[770px] rtl:xxs:min-h-[670px]',
            'ltr:xs:min-h-[680px] rtl:xs:min-h-[620px]',
            'ltr:sm:min-h-[600px] rtl:sm:min-h-[530px]',
            'ltr:md:min-h-[520px]  rtl:md:min-h-[500px]',
            'ltr:lg:min-h-[530px] rtl:lg:min-h-[540px]',
            'ltr:3xl:min-h-[610px]'
          )}
        >
          <h4
            className={classNames(
              'ltr:w-full rtl:w-full mt-10 relative z-10',
              'md:ltr:max-w-[388px] md:rtl:max-w-[340px] md: mt-14',
              'lg:ltr:max-w-[400px] lg:rtl:max-w-[260px]',
              'xl:ltr:max-w-[500px] xl:rtl:max-w-[450px]',
              '2xl:ltr:max-w-[640px] 2xl:rtl:max-w-[605px] 2xl:mt-24',
              'home-subtitle'
            )}
          >
            {t('Your trusted property management partner')}
          </h4>
          <h5 className="relative z-10 bg-white-opacity py-[24px] md:max-w-[70%] ltr:pr-[20px]">
            {t('1st-subtitle')}
          </h5>
          <BenefitSection />
        </div>
        <div className="hidden 3xl:flex 3xl:relative 3xl:shrink-0 ltr:-mr-24 rtl:-ml-24">
          <Image src={Illustration1} width={710} height={540} alt="" />
        </div>
      </div>
      <div className="absolute top-[260px] md:top-[100px] lg:top-[110px] 3xl:hidden ltr:right-0 rtl:left-0">
        <div className="hidden lg:flex">
          <Image src={Illustration1} width={710} height={540} alt="" />
        </div>
        <div className="hidden md:flex lg:hidden">
          <Image src={Illustration1Tablet} width={340} height={509} alt="" />
        </div>
        <div className="flex md:hidden">
          <Image src={Illustration1Mobile} width={240} height={316} alt="" />
        </div>
      </div>
    </>
  );
};
