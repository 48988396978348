import * as React from "react"
import { TIconProps } from "types/app"

function ArrowLeftIcon(props: TIconProps) {
  return (
    <svg
      width={42}
      height={10}
      viewBox="0 0 42 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.575 5.424a.6.6 0 010-.848L4.393.757a.6.6 0 01.849.849L1.848 5l3.394 3.394a.6.6 0 01-.849.849L.575 5.424zm40.472.176H.999V4.4h40.048v1.2z"
        fill="#E1FAFF"
      />
    </svg>
  )
}

export default ArrowLeftIcon