import React, { ReactElement } from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { DEFAULT_LANGUAGE } from 'lib/constants';
import HomeLayout from 'components/layouts/HomeLayout';
import HomePage from 'components/pages-content/home/Home';

const Home = () => <HomePage />;

Home.getLayout = function getLayout(page: ReactElement) {
  return <HomeLayout>{page}</HomeLayout>;
};

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale || DEFAULT_LANGUAGE, [
        'home-page',
        'common',
        'home-navigation',
        'dashboard-footer',
        'validation',
        'faq',
      ])),
    },
  };
}

export default Home;
