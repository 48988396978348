import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Image from 'next/image';
import React, { useMemo } from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';

type ItemT = {
  image: StaticImageData;
  title: string;
  subtitle: string;
  text: string;
};

type Props = {
  items: ItemT[];
};

export const HowItWorksSlider = ({ items }: Props) => {
  const { t } = useTranslation('home-page');
  const { t: common } = useTranslation('common');
  const router = useRouter();
  const settings = useMemo(
    () => ({
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      dir: router.locale === 'ar' ? 'rtl' : 'ltr',
      dotsClass: 'slick-dots how-slick-dots',
      customPaging: () => {
        return (
          <div
            style={{
              width: '30px',
              height: '8px',
              color: 'blue',
              border: '1px #189AB4 solid',
              borderRadius: '1px',
            }}
          />
        );
      },
    }),
    [router.locale]
  );

  const classNumberBlock = classNames(
    router.locale === 'ar' ? 'flex flex-row-reverse' : 'flex',
    'items-baseline font-Harietta'
  );

  return (
    <>
      <div
        className="lg:flex flex-col hidden min-[530px] bg-no-repeat bg-left-top"
        style={{
          backgroundImage: 'url(images/home/illust2-desc.png)',
          backgroundSize: '530px',
        }}
      >
        <div className="mb-10 mt-[115px]">
          <Slider {...settings}>
            {items.map((item, index) => (
              <div key={index}>
                <div className="flex-center space-x-[100px]">
                  <Image
                    src={item.image}
                    width={460}
                    height={300}
                    alt="screen"
                  />
                  <div className="max-w-[500px] h-[270px] ltr:text-left rtl:text-right">
                    <h2 className="text-secondary font-Harietta text-headline-2 font-700 mb-[50px]">
                      {t(item.title)}
                    </h2>
                    <p className="text-primary text-subtitle-1 ltr:font-600 rtl:font-700 mb-[30px]">
                      {t(item.subtitle)}
                    </p>
                    <p
                      dir={router.locale === 'ar' ? 'rtl' : 'ltr'}
                      className="text-body-2 leading-[24px] ltr:font-500 rtl:font-400 mb-[30px]"
                    >
                      {t(item.text)}
                    </p>
                    <p className={classNumberBlock}>
                      <span className="text-primary text-headline-1">
                        0{index + 1}
                      </span>
                      <span className="text-secondary text-body-1 mx-[10px] rtl:font-ar">
                        {common('of')}
                      </span>
                      <span className="text-secondary text-body-1"> 5</span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div
        className="lg:hidden flex flex-col min-h-[300px] bg-no-repeat bg-left-top"
        style={{
          backgroundImage: 'url(images/home/illust2-tabl.png)',
          backgroundSize: '300px',
        }}
      >
        <div className="mb-10 mt-0">
          <Slider {...settings}>
            {items.map((item, index) => (
              <div key={index}>
                <div className="flex flex-col items-start md:flex-row md:flex-center md:space-x-[40px] pt-[56px] md:pt-0">
                  <div className="w-full md:w-[300px] text-right">
                    <Image
                      src={item.image}
                      width={300}
                      height={196}
                      alt="screen"
                    />
                  </div>
                  <div className="max-w-[330px] ltr:text-left rtl:text-right">
                    <h2 className="text-secondary font-Harietta text-[22px] leading-[26px] md:text-headline-2 font-700 mb-[30px] mt-[90px] md:mt-0">
                      {t(item.title)}
                    </h2>
                    <p className="text-primary text-body-2 md:text-subtitle-1 ltr:font-600 rtl:font-700 mb-[30px]">
                      {t(item.subtitle)}
                    </p>
                    <p className="text-body-3 md:text-body-2 md:leading-[24px] ltr:font-500 rtl:font-400 mb-[30px]">
                      {t(item.text)}
                    </p>
                    <p className={classNumberBlock}>
                      <span className="text-primary text-headline-1">
                        0{index + 1}
                      </span>
                      <span className="text-secondary text-body-1 ltr:mx-[8px] rtl:mx-[10px] rtl:font-ar">
                        {common('of')}
                      </span>
                      <span className="text-secondary text-body-1"> 5</span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};
