import * as React from "react"
import { TIconProps } from "types/app"

function ArrowRightIcon(props: TIconProps) {
  return (
    <svg
      width={41}
      height={10}
      viewBox="0 0 41 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M40.472 5.424a.6.6 0 000-.848L36.653.757a.6.6 0 10-.848.849L39.199 5l-3.394 3.394a.6.6 0 10.848.849l3.819-3.819zM0 5.6h40.047V4.4H0v1.2z"
        fill="#E1FAFF"
      />
    </svg>
  )
}

export default ArrowRightIcon