import User1 from '/public/images/home/review/user1.png';
import User2 from '/public/images/home/review/user2.png';
import Screen1En from '/public/images/home/slider-how/screen1en.png';
import Illustration1 from '/public/images/home/illust3-1.png';
import Illustration2 from '/public/images/home/illust3-2.png';
import Illustration3 from '/public/images/home/illust3-3.png';

export const testimonialItems = [
  {
    image: User1,
    name: 'Jane Lennon',
    jobPosition: 'Property Manager',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
  },
  {
    image: User2,
    name: 'Pablo Johnsson',
    jobPosition: 'Property Owner',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
  },
  {
    image: User1,
    name: 'Jane Lennon',
    jobPosition: 'Property Manager',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
  },
  {
    image: User2,
    name: 'Pablo Johnsson',
    jobPosition: 'Property Owner',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
  },
  {
    image: User2,
    name: 'Pablo Johnsson',
    jobPosition: 'Property Owner',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
  },
];

export const faqItems = [
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit bore et dolore magnatrud exercitation ullamco laboris.',
  },
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
  },
  {
    title: 'Lorem ipsum dolor sit amet, consectetur?',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
  },
  {
    title:
      '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor?',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
  },
];

export const howItWorksItems = [
  {
    image: Screen1En,
    title: 'Get Started',
    subtitle: 'Add your properties and tenants with ease',
    text: 'Create an account through RETM website in simple steps.',
  },
  {
    image: Screen1En,
    title: 'Get Started',
    subtitle: 'Add your properties and tenants with ease',
    text: 'Start adding your commercial assets or real estate properties inside the digital dashboard.',
  },
  {
    image: Screen1En,
    title: 'Get Started',
    subtitle: 'Add your properties and tenants with ease',
    text: 'You can add other users like owners, managers, or companies.',
  },
  {
    image: Screen1En,
    title: 'Get Started',
    subtitle: 'Add your properties and tenants with ease',
    text: 'Keep track of your revenues and investments by issuing related reports',
  },
  {
    image: Screen1En,
    title: 'Get Started',
    subtitle: 'Add your properties and tenants with ease',
    text: 'You can now manage your property easily!',
  },
];

export const whyTheRetmItems = [
  {
    image: Illustration1,
    step: '01',
    title: 'Comprehensive Management',
    text: 'A comprehensive effective system that connects all assets programmatically to raise business efficiency, profitability, and reduce costs.',
  },
  {
    image: Illustration2,
    step: '02',
    title: 'Get paid',
    text: 'By collecting rents, tracking outstanding balances, and sending reminder notices to those who are due to pay.',
  },
  {
    image: Illustration3,
    step: '03',
    title: 'Long-term tenants',
    text: 'Offering high-quality property management will let your property shine by having good communication with your tenants and will turn them to rent for a long term.',
  },
];
