import Image from 'next/image';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import Slider, { CustomArrowProps } from 'react-slick';
import React from 'react';
import ArrowRightIcon from 'components/icons/ArrowRightIcon';
import ArrowLeftIcon from 'components/icons/ArrowLeftIcon';
import { useTranslation } from 'next-i18next';
import { testimonialItems } from '../../mocks/mocks';

const NextArrow = ({
  currentSlide,
  slideCount,
  ...props
}: CustomArrowProps) => (
  <div {...props} className="slick-next testimonials-slick-next">
    <ArrowRightIcon className="absolute top-[14px] right-[11px]" />
  </div>
);

const PrevArrow = ({
  currentSlide,
  slideCount,
  ...props
}: CustomArrowProps) => (
  <div {...props} className="slick-prev testimonials-slick-prev">
    <ArrowLeftIcon className="absolute top-[14px] left-[11px]" />
  </div>
);

export const TestimonialsSection = () => {
  const { t } = useTranslation('home-page');
  const router = useRouter();
  const settings = useMemo(
    () => ({
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      dir: router.locale === 'ar' ? 'rtl' : 'ltr',
      dotsClass: 'slick-dots testimonials-slick-dots',
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
      customPaging: () => {
        return (
          <div
            style={{
              width: '30px',
              height: '8px',
              color: 'blue',
              border: '1px #189AB4 solid',
              borderRadius: '1px',
            }}
          />
        );
      },
    }),
    [router.locale]
  );

  return (
    <div className="container mb-[100px] text-center">
      <Slider {...settings} className="testimonials-slick">
        {testimonialItems.map((item) => (
          <div
            className="home-card-shadow-rounded flex flex-col py-[48px] px-[25px] md:p-[48px] max-w-[330px] xs:max-w-[600px] mb-[60px] md:mb-[80px]"
            key={item.name}
          >
            <div className="flex flex-center mb-[30px] rtl:flex-row-reverse">
              <Image src={item.image} alt="user photo" width={80} height={80} />
              <div className="flex flex-col ltr:ml-[20px] rtl:mr-[20px]">
                <h4 className="text-body-2 text-secondary font-700 ltr:text-left rtl:text-right ltr:font-Harietta rtl:font-ar mb-[15px]">
                  {item.name}
                </h4>
                <p className="text-body-3 ltr:text-left rtl:text-right text-primary">
                  {t(item.jobPosition)}
                </p>
              </div>
            </div>
            <p className="text-body-3">{item.text}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};
